import type { CookieOptions, CookieRef } from '#app/composables/cookie'
import { useCookie } from '#app/composables/cookie'
import { createCookieOptions } from '~/helpers/createCookieOptions'

export const useAppCookie = <T = string | null | undefined>(
    name: string,
    options: CookieOptions<T> & {
        readonly?: false
    } = {},
): CookieRef<T> => {
    createCookieOptions(options)

    return useCookie<T>(name, options)
}
