import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type * as knowledgeBaseTypes from '~/ts/types/knowledge-base'

export default <T>(fetch: $Fetch<T, NitroFetchRequest>) => ({
    tree({ contentLanguage, onlyPublished, expand }: knowledgeBaseTypes.KBTree) {
        return fetch<knowledgeBaseTypes.KBTreeItem>('/project/{projectId}/kb/tree', {
            params: {
                onlyPublished,
                content_language: contentLanguage,
                expand: getFilledKeysString(expand),
            },
        })
    },
    treeUpdate({ id, contentLanguage, data }: knowledgeBaseTypes.KBTreeUpdate) {
        return fetch<knowledgeBaseTypes.KBTreeItem>(`/project/{projectId}/kb/${ id }`, {
            method: 'put',
            params: {
                content_language: contentLanguage,
            },
            body: data,
        })
    },
    treeUpdateLogo({ id, data }: knowledgeBaseTypes.KBTreeUpdateLogo) {
        return fetch<{ id: any, file: knowledgeBaseTypes.KBTreeItem['logo'] }>(`project/{projectId}/kb/logo/${ id }`, {
            method: 'post',
            body: data,
        })
    },
    treeDeleteLogo({ id }: knowledgeBaseTypes.KBTreeDeleteLogo) {
        return fetch(`project/{projectId}/kb/logo/${ id }`, {
            method: 'delete',
        })
    },
    treeUpdateOgImage({ id, data }: knowledgeBaseTypes.KBTreeUpdateOgImage) {
        return fetch<{ id: any, file: knowledgeBaseTypes.KBTreeItem['og_image'] }>(`project/{projectId}/kb/og-image/${ id }`, {
            method: 'post',
            body: data,
        })
    },
    treeDeleteOgImage({ id }: knowledgeBaseTypes.KBTreeDeleteOgImage) {
        return fetch(`project/{projectId}/kb/og-image/${ id }`, {
            method: 'delete',
        })
    },
    createCategory({ data, contentLanguage }: knowledgeBaseTypes.KBCategoryCreate) {
        return fetch<knowledgeBaseTypes.KBCategory>('/project/{projectId}/kb/category', {
            method: 'post',
            params: {
                content_language: contentLanguage,
            },
            body: data,
        })
    },
    updateCategory({ id, data, contentLanguage }: knowledgeBaseTypes.KBCategoryUpdate) {
        return fetch<knowledgeBaseTypes.KBCategory>(`/project/{projectId}/kb/category/${ id }`, {
            method: 'put',
            params: {
                content_language: contentLanguage,
            },
            body: data,
        })
    },
    deleteCategory({ id }: knowledgeBaseTypes.KBItemDelete) {
        return fetch(`/project/{projectId}/kb/category/${ id }`, {
            method: 'delete',
        })
    },
    oneArticle({ id, contentLanguage }: knowledgeBaseTypes.KBArticleOne) {
        return fetch<knowledgeBaseTypes.KBArticle>(`/project/{projectId}/kb/article/${ id }`, {
            params: {
                content_language: contentLanguage,
            },
        })
    },
    createArticle({ data, contentLanguage }: knowledgeBaseTypes.KBArticleCreate) {
        return fetch<knowledgeBaseTypes.KBArticle>('/project/{projectId}/kb/article', {
            method: 'post',
            params: {
                content_language: contentLanguage,
            },
            body: data,
        })
    },
    updateArticle({ id, data, contentLanguage }: knowledgeBaseTypes.KBArticleUpdate) {
        return fetch<knowledgeBaseTypes.KBArticle>(`/project/{projectId}/kb/article/${ id }`, {
            method: 'put',
            params: {
                content_language: contentLanguage,
            },
            body: data,
        })
    },
    deleteArticle({ id }: knowledgeBaseTypes.KBItemDelete) {
        return fetch(`/project/{projectId}/kb/article/${ id }`, {
            method: 'delete',
        })
    },
    moveItem({ id, data }: knowledgeBaseTypes.KBItemMove) {
        return fetch(`/project/{projectId}/kb/move/${ id }`, {
            method: 'put',
            params: data,
        })
    },
    articleFeedbacks({ id, query }: knowledgeBaseTypes.KBArticleFeedbackAll) {
        return fetch<{
            items: knowledgeBaseTypes.KBArticleFeedbackItem[]
        }>(`/project/{projectId}/kb/article/${ id }/feedback`, {
            params: { id },
            query,
        })
    },
    deleteArticleFeedback({ articleId, feedbackId }: knowledgeBaseTypes.KBArticleFeedbackDelete) {
        return fetch(`/project/{projectId}/kb/article/${ articleId }/feedback/${ feedbackId }`, {
            method: 'delete',
        })
    },
})
