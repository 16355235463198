import type { FetchContext } from 'ofetch'
import type { ApiContext } from '~/ts/types/api'
import replaceRequestVariables from '~/helpers/fetch/replaceRequestVariables'
import fillRequestOptions from '~/helpers/fetch/fillRequestOptions'

export default async (apiContext: ApiContext, fetchContext: FetchContext): Promise<void> => {
    replaceRequestVariables(fetchContext)

    await fillRequestOptions(fetchContext.options, apiContext)

    if (apiContext.debug) {
        await apiContext.nuxtApp.runWithContext(() => {
            useLog(fetchContext.request, 'onRequest', {
                hint: true,
                devOnly: true,
            })
        })
    }
}
