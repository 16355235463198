import { default as _91token_93NPfbTIRZz9Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue?macro=true";
import { default as _91token_93_46clientAxHFxLTGQiMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/codebuild/output/src3916994818/src/spilky-client/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91token_93bX7dxP8arxMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue?macro=true";
import { default as _91token_93_46clientRZy4VYKOX5Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue?macro=true";
import { default as google_46client9D51y0eGzMMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue?macro=true";
import { default as _91token_93SlwTfPyTAAMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue?macro=true";
import { default as requestYe9QvY8kinMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue?macro=true";
import { default as sign_45inN36ExhOkvIMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-in.vue?macro=true";
import { default as indexD9loddcpOsMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue?macro=true";
import { default as _1II8j2UZIZNMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue?macro=true";
import { default as _2l6a7Ny5HVFMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue?macro=true";
import { default as _3s216E9cQ8RMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue?macro=true";
import { default as _4gdTWRgRCJvMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue?macro=true";
import { default as stepzPg1g4OYoiMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue?macro=true";
import { default as widget_45simulator_45_91token_93_46clientTrTcunpg0SMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/external/widget-simulator-[token].client.vue?macro=true";
import { default as indexuS6Ak3iVD1Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/index.vue?macro=true";
import { default as accountfoZOJzWEPzMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/account.vue?macro=true";
import { default as _91id_93m4w0t7xuN8Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue?macro=true";
import { default as billing6tBZNJcv9UMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/billing.vue?macro=true";
import { default as _91cid_935u1h4BVweGMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue?macro=true";
import { default as _91kind_93UJ64fbnh87Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/chat/[kind].vue?macro=true";
import { default as chatkBgnTSvpfSMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/chat.vue?macro=true";
import { default as inactive_45operatorc0t50vH0Q3Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/inactive-operator.vue?macro=true";
import { default as knowledge_45basekfpvVfIGd8Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/knowledge-base.vue?macro=true";
import { default as account8XjyET3OMzMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/account.vue?macro=true";
import { default as basic_45settingsoJqWzlek3IMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue?macro=true";
import { default as operatorsxAz9yRBsXuMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue?macro=true";
import { default as basic_45settingsmtrIHogaU2Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue?macro=true";
import { default as operators4ufta2E4bSMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue?macro=true";
import { default as basic_45settingsJXbhdzBOQtMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue?macro=true";
import { default as code_45scripteCtQzpZR8DMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue?macro=true";
import { default as contactsFlWFgNPLrHMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue?macro=true";
import { default as operatorspxLEhjGC2tMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue?macro=true";
import { default as personalizationWFyxVN7bdZMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue?macro=true";
import { default as configureB0TwnVaqHvMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue?macro=true";
import { default as telegram2h7LSGpbSdMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue?macro=true";
import { default as viberZ7JQe7rm2YMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue?macro=true";
import { default as channel8P7NZlHkHWMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel.vue?macro=true";
import { default as settingsuMEypZ5nDEMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue?macro=true";
import { default as work_45analyticsPz7ASrncQ4Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue?macro=true";
import { default as work_45scheduleD9Is6ONBHkMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue?macro=true";
import { default as editvJedfemZrwMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue?macro=true";
import { default as createx1Rmtk2FUOMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/create.vue?macro=true";
import { default as operatorrUlsoQJmQsMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator.vue?macro=true";
import { default as logomqXb9Pyax4Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue?macro=true";
import { default as settingsoLdP0SJ8uyMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue?macro=true";
import { default as editYfiSkZwP49Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue?macro=true";
import { default as createNWE6ejg4TjMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project/create.vue?macro=true";
import { default as projectfmSbI7YfHGMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project.vue?macro=true";
import { default as basic_45settingsQXeqotOYPOMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/basic-settings.vue?macro=true";
import { default as personalizations8MJ2GGrrdMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/personalization.vue?macro=true";
import { default as seo_45and_45analytics0snomOnKEAMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/seo-and-analytics.vue?macro=true";
import { default as public_45knowledge_45baseHa6kBGKBP0Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base.vue?macro=true";
import { default as editeH9ipGS3bJMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue?macro=true";
import { default as createf2E9QbHQOmMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue?macro=true";
import { default as quick_45answervjWQz1IOu1Meta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue?macro=true";
import { default as setting9attlGGVxwMeta } from "/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting.vue?macro=true";
export default [
  {
    name: "language-auth-change-password-token",
    path: "/:language?/auth/change-password/:token()",
    meta: _91token_93NPfbTIRZz9Meta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue")
  },
  {
    name: "language-auth-confirm-token",
    path: "/:language?/auth/confirm/:token()",
    meta: _91token_93_46clientAxHFxLTGQiMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue"))
  },
  {
    name: "language-auth-create-password-token",
    path: "/:language?/auth/create-password/:token()",
    meta: _91token_93bX7dxP8arxMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue")
  },
  {
    name: "language-auth-invite-token",
    path: "/:language?/auth/invite/:token()",
    meta: _91token_93_46clientRZy4VYKOX5Meta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue"))
  },
  {
    name: "language-auth-oauth-google",
    path: "/:language?/auth/oauth/google",
    meta: google_46client9D51y0eGzMMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue"))
  },
  {
    name: "language-auth-reset-password-token",
    path: "/:language?/auth/reset-password/:token()",
    meta: _91token_93SlwTfPyTAAMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue")
  },
  {
    name: "language-auth-reset-password-request",
    path: "/:language?/auth/reset-password/request",
    meta: requestYe9QvY8kinMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue")
  },
  {
    name: "language-auth-sign-in",
    path: "/:language?/auth/sign-in",
    meta: sign_45inN36ExhOkvIMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-in.vue")
  },
  {
    name: "language-auth-sign-up",
    path: "/:language?/auth/sign-up",
    meta: indexD9loddcpOsMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue")
  },
  {
    name: "language-auth-sign-up-step",
    path: "/:language?/auth/sign-up/step",
    meta: stepzPg1g4OYoiMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue"),
    children: [
  {
    name: "language-auth-sign-up-step-1",
    path: "1",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue")
  },
  {
    name: "language-auth-sign-up-step-2",
    path: "2",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue")
  },
  {
    name: "language-auth-sign-up-step-3",
    path: "3",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue")
  },
  {
    name: "language-auth-sign-up-step-4",
    path: "4",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue")
  }
]
  },
  {
    name: "external-widget-simulator-token",
    path: "/external/widget-simulator-:token()",
    meta: widget_45simulator_45_91token_93_46clientTrTcunpg0SMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3916994818/src/spilky-client/pages/external/widget-simulator-[token].client.vue"))
  },
  {
    name: "index",
    path: "/",
    meta: indexuS6Ak3iVD1Meta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/index.vue")
  },
  {
    name: "p-pid-account",
    path: "/p/:pid()/account",
    meta: accountfoZOJzWEPzMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/account.vue")
  },
  {
    name: "p-pid-billing",
    path: "/p/:pid()/billing",
    meta: billing6tBZNJcv9UMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/billing.vue"),
    children: [
  {
    name: "p-pid-billing-invoice-id",
    path: "invoice/:id()",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue")
  }
]
  },
  {
    name: "p-pid-chat",
    path: "/p/:pid()/chat",
    meta: chatkBgnTSvpfSMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/chat.vue"),
    children: [
  {
    name: "p-pid-chat-kind",
    path: ":kind()",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/chat/[kind].vue"),
    children: [
  {
    name: "p-pid-chat-kind-cid",
    path: ":cid()",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue")
  }
]
  }
]
  },
  {
    name: "p-pid-inactive-operator",
    path: "/p/:pid()/inactive-operator",
    meta: inactive_45operatorc0t50vH0Q3Meta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/inactive-operator.vue")
  },
  {
    name: "p-pid-knowledge-base",
    path: "/p/:pid()/knowledge-base",
    meta: knowledge_45basekfpvVfIGd8Meta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/knowledge-base.vue")
  },
  {
    name: "p-pid-setting",
    path: "/p/:pid()/setting",
    meta: setting9attlGGVxwMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting.vue"),
    children: [
  {
    name: "p-pid-setting-account",
    path: "account",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/account.vue")
  },
  {
    name: "p-pid-setting-channel",
    path: "channel",
    meta: channel8P7NZlHkHWMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel.vue"),
    children: [
  {
    name: "p-pid-setting-channel-id-configure",
    path: ":id()/configure",
    meta: configureB0TwnVaqHvMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue"),
    children: [
  {
    name: "p-pid-setting-channel-id-configure-telegram-basic-settings",
    path: "telegram/basic-settings",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-telegram-operators",
    path: "telegram/operators",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-viber-basic-settings",
    path: "viber/basic-settings",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-viber-operators",
    path: "viber/operators",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-basic-settings",
    path: "widget/basic-settings",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-code-script",
    path: "widget/code-script",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-contacts",
    path: "widget/contacts",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-operators",
    path: "widget/operators",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-personalization",
    path: "widget/personalization",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue")
  }
]
  },
  {
    name: "p-pid-setting-channel-connect-telegram",
    path: "connect/telegram",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue")
  },
  {
    name: "p-pid-setting-channel-connect-viber",
    path: "connect/viber",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue")
  }
]
  },
  {
    name: "p-pid-setting-operator",
    path: "operator",
    meta: operatorrUlsoQJmQsMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator.vue"),
    children: [
  {
    name: "p-pid-setting-operator-id-edit",
    path: ":id()/edit",
    meta: editvJedfemZrwMeta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue"),
    children: [
  {
    name: "p-pid-setting-operator-id-edit-settings",
    path: "settings",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue")
  },
  {
    name: "p-pid-setting-operator-id-edit-work-analytics",
    path: "work-analytics",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue")
  },
  {
    name: "p-pid-setting-operator-id-edit-work-schedule",
    path: "work-schedule",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue")
  }
]
  },
  {
    name: "p-pid-setting-operator-create",
    path: "create",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/operator/create.vue")
  }
]
  },
  {
    name: "p-pid-setting-project",
    path: "project",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project.vue"),
    children: [
  {
    name: "p-pid-setting-project-id-edit",
    path: ":id()/edit",
    meta: editYfiSkZwP49Meta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue"),
    children: [
  {
    name: "p-pid-setting-project-id-edit-logo",
    path: "logo",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue")
  },
  {
    name: "p-pid-setting-project-id-edit-settings",
    path: "settings",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue")
  }
]
  },
  {
    name: "p-pid-setting-project-create",
    path: "create",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/project/create.vue")
  }
]
  },
  {
    name: "p-pid-setting-public-knowledge-base",
    path: "public-knowledge-base",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base.vue"),
    children: [
  {
    name: "p-pid-setting-public-knowledge-base-basic-settings",
    path: "basic-settings",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/basic-settings.vue")
  },
  {
    name: "p-pid-setting-public-knowledge-base-personalization",
    path: "personalization",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/personalization.vue")
  },
  {
    name: "p-pid-setting-public-knowledge-base-seo-and-analytics",
    path: "seo-and-analytics",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/public-knowledge-base/seo-and-analytics.vue")
  }
]
  },
  {
    name: "p-pid-setting-quick-answer",
    path: "quick-answer",
    meta: quick_45answervjWQz1IOu1Meta || {},
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue"),
    children: [
  {
    name: "p-pid-setting-quick-answer-id-edit",
    path: ":id()/edit",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue")
  },
  {
    name: "p-pid-setting-quick-answer-create",
    path: "create",
    component: () => import("/codebuild/output/src3916994818/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue")
  }
]
  }
]
  }
]