import * as Sentry from '@sentry/vue'
import { useUserStore } from '~/stores/user'
import pluginCheckContext from '~/helpers/pluginCheckContext'

export default defineNuxtPlugin((nuxtApp) => {
    const userStore = useUserStore()

    if (useError().value || !pluginCheckContext(nuxtApp.$router as any, userStore)) {
        return
    }

    const {
        sentry,
        environment,
        isProd,
        siteUrl,
        apiUrl,
    } = nuxtApp.$config.public

    if (!sentry.dsn) {
        return
    }

    const tracesSampleRate = isProd ? 0.2 : 1.0
    const replaysSessionSampleRate = isProd ? 0.1 : 1.0
    const replaysOnErrorSampleRate = isProd ? 0.1 : 1.0

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: environment + ':client',
        integrations: [
            Sentry.replayIntegration(),
        ],
        tracePropagationTargets: [
            'localhost',
            siteUrl,
            apiUrl,
        ],
        tracesSampleRate, //  (1.0) Capture 100% of the transactions
        replaysSessionSampleRate, // (0.1) This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate, // (1.0) If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })

    if ('$router' in nuxtApp) {
        Sentry.addIntegration(
            Sentry.browserTracingIntegration({
                router: nuxtApp.$router as any,
                routeLabel: 'path',
            }),
        )
    }

    nuxtApp.hook('app:created', vueApp => {
        Sentry.addIntegration(
            Sentry.vueIntegration({
                app: vueApp,
                attachErrorHandler: false,
            }),
        )
    })

    nuxtApp.hook('app:error', (error) => {
        Sentry.captureException(error, {
            extra: {
                hook: 'app:error',
                userId: userStore.user?.id,
                fullPath: nuxtApp.$router?.currentRoute?.value?.fullPath || 'Unknown Path',
            },
        })
    })

    nuxtApp.hook('vue:error', (error, instance, info) => {
        Sentry.captureException(error, {
            extra: {
                hook: 'vue:error',
                info,
            },
        })
    })

    const setUser = (): void => {
        Sentry.setUser({
            id: userStore.user.id,
            email: userStore.user.email,
        })
    }

    if (userStore.isAuthenticated) {
        setUser()
    } else {
        userStore.onIn(setUser)
    }
})
