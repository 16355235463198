import type { CookieOptions } from '#app/composables/cookie'

export const createCookieOptions = (options: CookieOptions = {}): CookieOptions => {
    const { public: { isModeProduction } } = useRuntimeConfig()

    const oneYearInSeconds = 31536000

    options.maxAge = options.maxAge ?? oneYearInSeconds
    options.secure = options.secure ?? isModeProduction

    return options
}
