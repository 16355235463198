import type { NuxtApp } from 'nuxt/app'
import type { ApiContext, ApiFetchOptionsExtra } from '~/ts/types/api'
import { useUserStore } from '~/stores/user'
import handleRequest from '~/helpers/fetch/handleRequest'
import handleRequestError from '~/helpers/fetch/handleRequestError'
import handleResponse from '~/helpers/fetch/handleResponse'
import handleResponseError from '~/helpers/fetch/handleResponseError'

type Injections = {
    api: ReturnType<typeof $fetch.create>
}

declare module '#app' {
    interface NuxtApp {
        $api: Injections['api']
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $api: Injections['api']
    }
}

declare module 'ofetch' {
    // eslint-disable-next-line
    interface ResolvedFetchOptions extends ApiFetchOptionsExtra {
    }

    // eslint-disable-next-line
    interface FetchOptions extends ApiFetchOptionsExtra {
    }
}

export default defineNuxtPlugin((nuxtApp: NuxtApp): { provide: Injections } => {
    const { public: { apiUrl } } = useRuntimeConfig()

    const apiContext: ApiContext = {
        debug: false,
        nuxtApp,
        broadcaster: useBroadcaster(),
        lang: useLang(),
        userStore: useUserStore(),
        token: useAppCookie<string>('token'),
    }

    const api = $fetch.create({
        baseURL: apiUrl,
        retry: false,
        onRequest: handleRequest.bind(this, apiContext),
        onRequestError: handleRequestError.bind(this, apiContext),
        onResponse: handleResponse.bind(this, apiContext),
        onResponseError: handleResponseError.bind(this, apiContext),
    })

    return {
        provide: {
            api,
        },
    }
})
